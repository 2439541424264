<template>
  <div class="today_history">
    <div class="today_history_title">
      <img src="../../assets/img/views/gobank.png" @click="$router.go(-1)" />
      <span>{{ $route.meta.title }}</span>
    </div>
    <div class="today_history_serch">
      <div>
        <el-date-picker v-model="strTime" type="date" placeholder="开始时间" :picker-options="pickerOptions">
        </el-date-picker>
        <span>一</span>
        <el-date-picker v-model="endTime" type="date" placeholder="结束时间" :picker-options="pickerOptions">
        </el-date-picker>
        <el-button type="primary" style="margin-left: 20px;" size="medium" @click="serch">搜索</el-button>
      </div>
    </div>
    <!-- 历史列表 -->
    <div class="today_history_cont" v-loading="loading">
      <div class="today_history_list" v-for="(x, i) in hotTableData" :key="i">
        <div class="today_history_list_t">
          <p class="today_history_list_title"><img src="../../assets/img/views/monitoringScheme/quanquan.png" />{{
            x.statisticalTime }}日热搜分布
          </p>
          <el-table class="today_history_list_tab" :data="x.list"
            :header-cell-style="{ textAlign: 'center', color: 'black', backgroundColor: '#f5f6fa' }"
            :cell-style="{ height: '65px' }" height="375" stripe>
            <el-table-column label="热榜平台">
              <template slot-scope="scope">
                <div class="zb_hotdata_today_platform">
                  <img :src="hotTypeImgList[scope.row.hotType]">
                  <span>{{ scope.row.hotTypeName }} - {{ scope.row.areaName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="最高排名">
              <template slot-scope="scope">
                <div class="zb_hotdata_today_rank">
                  {{ scope.row.maxRanking ? scope.row.maxRanking : "top" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="热榜标题" width="350">
              <template slot-scope="scope">
                <div class="zb_hotdata_today_title" @click="gourlPath(scope.row.urlPath)">
                  {{ scope.row.title }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="最高热度" width="150">
              <template slot-scope="scope">
                <div class="zb_hotdata_today_Clicks">
                  {{ scope.row.maxRanking == 0 ? '置顶热榜' : formatNumber(scope.row.maxClicks) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="releaseTime" label="上榜时间" align="center"></el-table-column>
            <el-table-column label="退榜时间">
              <template slot-scope="scope">
                <div class="zb_hotdata_today_time">
                  {{ scope.row.withdrawalTime ? scope.row.withdrawalTime : "暂未退榜" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="durationTime" label="持续时长" align="center"></el-table-column>
          </el-table>
          <div>
            <el-pagination @current-change="handleCurrentChange(x.currentPage, x, i)" :current-page.sync="x.currentPage"
              :page-size="10" layout="total, prev, pager, next" :total="x.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTimeAxis, todayHotRank } from "../../api/hotData/list";
export default {
  data() {
    return {
      loading: false,
      hotTableData: [],
      hotTypeList: {
        1: '百度',
        2: '今日头条(全国)',
        3: '今日头条(本地)',
        4: '知乎',
        5: '快手',
        6: '新浪',
        7: '抖音(热榜)',
        8: '抖音(社会)',
        9: '抖音(本地)',
        10: '哔哩哔哩'
      },
      hotTypeImgList: {
        1: require('.././../assets/img/views/meiti/baidu.png'),
        2: require('.././../assets/img/views/meiti/toutiao.png'),
        3: require('.././../assets/img/views/meiti/toutiao.png'),
        4: require('.././../assets/img/views/meiti/zhihu.png'),
        5: require('.././../assets/img/views/meiti/kuaishou.png'),
        6: require('.././../assets/img/views/meiti/weibo.png'),
        7: require('.././../assets/img/views/meiti/douyin.png'),
        8: require('.././../assets/img/views/meiti/douyin.png'),
        9: require('.././../assets/img/views/meiti/douyin.png'),
        10: require('.././../assets/img/views/meiti/bzhan.png'),
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      strTime: new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000),
      endTime: new Date(),
    }
  },
  methods: {
    serch() {
      this.$message.closeAll();
      if (this.endTime == null) {
        this.$message({
          message: '结束时间不能为空！',
          type: 'warning'
        });
        return false;
      }
      if (this.strTime == null) {
        this.$message({
          message: '开始时间不能为空！',
          type: 'warning'
        });
        return false;
      }
      if (this.endTime.getTime() < this.strTime.getTime()) {
        this.$message({
          message: '结束时间不能小于开始时间！',
          type: 'warning'
        });
        return false;
      }
      this.getHot();
    },
    // 计算万单位
    formatNumber(num) {
      num = Number(num);
      if (num == 0) {
        return num + '';
      } else
        if (num > 1 && num < 10000) {
          return num + '';
        } else {
          return (num / 10000).toFixed(2) + '万';
        }
    },
    gourlPath(url) {
      window.open(url, "_blank");
    },
    async handleCurrentChange(val, data, i) {
      let res = await todayHotRank({
        "organId": this.$store.state.user.organizationId,
        "startTime": this.$calcu.timeCycle(data.statisticalTime, "yyyy-MM-dd") + " 00:00:00",
        "endTime": this.$calcu.timeCycle(data.statisticalTime, "yyyy-MM-dd") + " 23:59:59",
        pageNum: val,
        pageSize: 10
      });
      this.$set(this.hotTableData, i, {
        currentPage: val,
        docCout: this.hotTableData[i].docCout,
        initialLaunchTime: this.hotTableData[i].initialLaunchTime,
        list: res.data.rows,
        statisticalTime: this.hotTableData[i].statisticalTime,
        terraceNum: this.hotTableData[i].terraceNum,
        total: res.data.total
      });
    },
    async getHot() {
      this.loading = true;
      let res = await getTimeAxis({
        "organId": this.$store.state.user.organizationId,
        "startTime": this.$calcu.timeCycle(this.strTime, "yyyy-MM-dd") + " 00:00:00",
        "endTime": this.$calcu.timeCycle(this.endTime, "yyyy-MM-dd") + " 23:59:59",
      });
      let arr = [];
      this.hotTableData = res.data.data;
      this.hotTableData.forEach((x) => {
        x.currentPage = 1;
        x.total = 0;
        arr.push(todayHotRank({
          "organId": this.$store.state.user.organizationId,
          "startTime": this.$calcu.timeCycle(x.statisticalTime, "yyyy-MM-dd") + " 00:00:00",
          "endTime": this.$calcu.timeCycle(x.statisticalTime, "yyyy-MM-dd") + " 23:59:59",
          pageNum: x.currentPage,
          pageSize: 10
        }));
      });
      this.$http.all(arr).then((res) => {
        res.forEach((x, i) => {
          this.hotTableData[i].total = x.data.total;
          this.hotTableData[i].list = x.data.rows;
        });
        this.loading = false;
      });
    }
  },
  created() {
    this.serch();
  },
}
</script>

<style scoped>
.today_history {
  width: 100%;
  height: 100%;
}

.today_history_title {
  position: fixed;
  width: calc(100% - 228px);
  height: 56px;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  padding: 0 23px;
  z-index: 9999;
}

.today_history_title img {
  cursor: pointer;
}

.today_history_title span {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin-left: 15px;
}

.today_history_cont {
  padding-top: 170px;
}

.today_history_list {
  width: 100%;
  height: 540px;
  padding: 12px 44px;
}

.today_history_list_t {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 24px 24px 0 24px;
}

.today_history_list_title {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.today_history_list_tab {
  margin-top: 25px;
}

.today_history_serch {
  position: fixed;
  width: calc(100% - 228px);
  height: 102px;
  padding: 23px 0;
  top: 136px;
  z-index: 9999;
  background: #f4f5fa;
}

.today_history_serch>div>span {
  margin: 0 10px;
}

.today_history_serch>div {
  display: flex;
  height: 79px;
  align-items: center;
  background: #fff;
  padding: 0 23px;
}

.zb_hotdata_today_platform {
  display: flex;
  align-items: center;
  justify-content: center;
}

.zb_hotdata_today_platform img {
  margin-right: 10px;
}

.zb_hotdata_today_title {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.zb_hotdata_today_rank {
  text-align: center;
}

.zb_hotdata_today_Clicks,
.zb_hotdata_today_time {
  text-align: center;
}
</style>